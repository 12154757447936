import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const Wrapper = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 80px;
  text-transform: uppercase;
  text-align: center;
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Wrapper className="text-blue-600">
      <p>Page not found!</p>
      <Link
        className="text-lg py-5 px-4 bg-blue-600 text-white rounded-full hover:bg-blue-700"
        to="/"
      >
        Go home
      </Link>
    </Wrapper>
  </Layout>
);

export default NotFoundPage;
